import React from "react"
import { Link } from "gatsby"
import Layout from '../../components/layout'
import { StaticImage } from "gatsby-plugin-image"




const IncusHighlightedDenverGazetteMay2023 = ({ data }) => {
    return (
        <Layout pageTitle="INCUS | INCUS Highlighted by Denver Gazette; Gov. Polis">
            <h1>INCUS Highlighted in Denver Gazette and by Gov. Polis</h1>
            <StaticImage
                alt="A rendering of three INCUS satellites overflying convective storms on Earth"
                src="../../images/INCUS_rendering.jpg"
                layout="constrained"
                placeholder="blurred"
                objectPosition="0 0"
                aspectRatio={16 / 4}
                height={300}
                objectFit="contain"
                transformOptions={{ fit: "cover" }}
            />
            <h5>May 2, 2023</h5>

            <p>DENVER, CO- The INvestigation of Convective UpdraftS (INCUS), a NASA Earth Ventures Mission led by Colorado State University (CSU) and managed by NASA's Jet Propulsion Laboratory, was highlighted in a <a href="https://denvergazette.com/news/business/colorado-incus-nasa-mission-weather-forecasting/article_342e4654-e4a7-11ed-babe-7f9de2610ff5.html" target="_blank">recent Denver Gazette article.</a>. In addition to CSU's leadership of the mission, two Colorado companies, Blue Canyon Technologies and Tendeg, are involved in the construction of the spacecraft and engineering of the radar antennas.
            </p>

            <p>
                Colorado Gov. Jared Polis recently highlighted the significant Coloradan contributions to the CSU-led NASA mission.
                <blockquote className="twitter-tweet"><p lang="en" dir="ltr">We’re about to get some very accurate weather reports in Colorado thanks to researchers at <a href="https://twitter.com/ColoradoStateU?ref_src=twsrc%5Etfw">@ColoradoStateU</a> who will lead a $177 million NASA mission to gather never-before-seen data from satellites built by Colorado companies. <a href="https://t.co/U0bRfXIFjs">https://t.co/U0bRfXIFjs</a> <a href="https://t.co/LHluX5OTws">pic.twitter.com/LHluX5OTws</a></p>&mdash; Governor Jared Polis (@GovofCO) <a href="https://twitter.com/GovofCO/status/1653580171490865153?ref_src=twsrc%5Etfw">May 3, 2023</a></blockquote>
            </p>


        </Layout >

    )
}

export default IncusHighlightedDenverGazetteMay2023

